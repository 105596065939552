<template>
  <t-form-card
    :title="`Endereço`"
    :edit="true"> <!-- setado true para não edição -->
    <template v-slot:content>
      <div class="content">
        <div class="fields">
          <div class="sixteen wide field">
            <div class="field">
              <label for="donorCep" class="label">CEP</label>
              <input
                type="text"
                id="donorCep"
                name="donor[cep]"
                placeholder="CEP"
                :class="['ui input', { 'disabled': !editForm }]"
                :readonly="!editForm"
                v-model="address.zipcode">
            </div>
            <div class="field"><!-- ainda tá com pau -->
              <label for="donorAddress" class="label">Endereço/Número</label>
              <div class="wrap-form-inline">
                <input
                  type="text"
                  id="donorAddress"
                  name="donor[address]"
                  placeholder="Endereço"
                  :class="['ui input left', { 'disabled': !editForm }]"
                  :readonly="!editForm"
                  v-model="address.street">
                <input
                  type="number"
                  id="donorAddress"
                  name="donor[address]"
                  placeholder="Número"
                  :class="['ui input right number', { 'disabled': !editForm }]"
                  :readonly="!editForm"
                  v-model="address.number">
              </div>
            </div>
            <div class="field">
              <label for="donorAddressComplement" class="label">Complemento/Bairro</label>
              <div class="wrap-form-inline">
                <input
                  type="text"
                  id="donorAddressComplement"
                  name="donor[addressComplement]"
                  placeholder="Endereço"
                  :class="['ui input left', { 'disabled': !editForm }]"
                  :readonly="!editForm"
                  v-model="address.complement">
                <input
                  type="text"
                  id="donorAddressNeighborhood"
                  name="donor[addressNeighborhood]"
                  placeholder="Bairro"
                  :class="['ui input right', { 'disabled': !editForm }]"
                  :readonly="!editForm"
                  v-model="address.neighborhood">
              </div>
            </div>
            <div class="sixteen field">
              <label for="donorState" class="label">Estado/Cidade</label>
              <div class="wrap-form-inline">
                <div
                  v-show="editForm"
                  :class="['ui fluid dropdown', {'disabled' : !editForm}]">
                  <input type="hidden" name="state" v-model="state">
                  <i class="dropdown icon"></i>
                  <div class="default text">UF</div>
                  <div class="menu">
                    <div
                      v-for="(item, key) in states"
                      :key="key"
                      :data-value="item.uf"
                      class="item">{{ item.uf }}</div>
                  </div>
                </div>
                <input
                  v-show="!editForm"
                  type="text"
                  name="donor[state]"
                  placeholder="UF"
                  class="ui input right disabled"
                  readonly
                  v-model="state" />
                <input
                  type="text"
                  name="donor[city]"
                  placeholder="Cidade"
                  :class="['ui input right', { 'disabled': !editForm }]"
                  :readonly="!editForm"
                  v-model="address.city" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-if="false" v-slot:actions>
      <div class="actions" v-show="editForm">
        <button class="tui tuim ui button" @click.prevent="resetForm">Cancelar</button>
        <button class="tui tuim ui primary button" @click.prevent="save">Salvar</button>
      </div>
    </template>
  </t-form-card>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: "TCardAddress",
  components: { TFormCard: () => import('@/components/card/index.vue') },
  props: {
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState({
      states: ({ donors }) => donors.states,
      donor: ({ donors }) => donors.donorDetail,
      editForm: ({ donors }) => donors.editForm
    }),

    address: ({ donor }) => {
      let address = {}
      if (donor.addresses && donor.addresses.length > 0) {
        address = donor.addresses[0]
      }
      return address
    },

    state: {
      get() {
        return this.address.state ? this.address.state.toUpperCase() : ''
      },
      set(value) {
        if (value) {
          this.address.state = value
        }
      }
    }
  },
  methods: {
    bindPlugins() {
      $('.ui.fluid.dropdown').dropdown({
        direction: 'upward'
      })
      setTimeout(() => {
        $('.ui.fluid.dropdown').dropdown()
      }, 1300)
    }
  },

  mounted() {
    this.bindPlugins()
    // setTimeout(() => {
    //   this.bindPlugins()
    // }, 1300)
  }
}
</script>
